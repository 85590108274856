import * as React from "react"
import {graphql} from "gatsby";
import {useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import Text from "../components/atoms/Text/Text";
import ColoredBox from "../components/molecules/ColoredTextBox/ColoredBox";

type propType = {
    data: any
    classes: any,
    context: any
}
const DisclaimerPage = ({data}: propType) => {
    const {t} = useTranslation();

    return (
        <>
            <ColoredBox>
                <FullWidthContainer>
                    <Text text={t('disclaimer:introduction')}/>
                    <h3>{t('disclaimer:headline')}</h3>
                    <Text text= {t('disclaimer:headline2')}/>
                    <p>
                        <Text text={t('disclaimer:sectionContents.headline')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionContents.copy1')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionAvailiability.headline')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionAvailiability.copy1')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionExternalLinks.headline')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionExternalLinks.copy1')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionAdvertisements.headline')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionAdvertisements.copy1')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionContract.headline')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionContract.copy1')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionCopyright.headline')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionCopyright.copy1')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionPrivacy.headline')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionPrivacy.copy1')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionPrivacy.copy2')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionRights.headline')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionRights.copy1')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionTermsOfUse.headline')}/>
                    </p>
                    <p>
                        <Text text={t('disclaimer:sectionTermsOfUse.copy1')}/>
                    </p>
                </FullWidthContainer>
            </ColoredBox>
        </>
    )
}

export default DisclaimerPage

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "disclaimer"]}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;